<template>
  <div class="center-video-component">
    <!-- 顶部监控视频框 -->
    <div class="top-arm-video">
      <!-- 视觉方向 / 手动-->
      <template v-if="videoStatus == 1 || videoStatus == 2">
        <LivePlayer h5id="live4" :token="currentLoginedRobot.h5sToken.live4" :isDisable="true" :isShowMiddleBtn="false"  :key="videoKey" :isStop="isStop" :videoStatus="videoStatus"/>  
      </template>
      <template v-else>
        <LivePlayer h5id="live1" :token="currentLoginedRobot.h5sToken.live1" :isDisable="true" :isShowMiddleBtn="false" :key="videoKey" :isStop="isStop" :videoStatus="videoStatus"/>
      </template>
    </div>

    <div class="visible-infrared-video">
      <!-- 左下角视频 -->
      <div class="visible-video">
        <div class="video-type-tab" v-if="modeType == 1">
          <el-radio-group v-model="videoModeType" size="mini">
            <el-radio-button label="可见光"></el-radio-button>
            <el-radio-button label="红外"></el-radio-button>
          </el-radio-group>
        </div>
        <!-- 可见光还是红外 -->
        <template v-if="modeType == 1 && videoModeType == '红外'">
          <LivePlayer h5id="live3" :token="currentLoginedRobot.h5sToken.live3" :isDisable="true" :isShowMiddleBtn="false" :key="videoModeKey" :videoStatus="videoStatus" @isOpenRed="isOpenRed" />  
        </template>
        <template v-else>
          <LivePlayer h5id="live2" :token="currentLoginedRobot.h5sToken.live2" :isDisable="true" :isShowMiddleBtn="false" :key="videoModeKey" :videoStatus="videoStatus" @isOpenRed="isOpenRed"/>
        </template>
      </div>

      <!-- 右下角视频 -->
      <div class="infrared-video">
        <!-- 视觉方向 / 手动-->
        <template v-if="videoStatus == 1">
          <LivePlayer h5id="live5" :token="currentLoginedRobot.h5sToken.live5" :isDisable="true" :isShowMiddleBtn="false" :key="videoKey" :isStop="isStop" :videoStatus="videoStatus"/>  
        </template>
        <template v-else-if="videoStatus == 2">
          <LivePlayer h5id="live6" :token="currentLoginedRobot.h5sToken.live6" :isDisable="true" :isShowMiddleBtn="false" :key="videoKey" :isStop="isStop" :videoStatus="videoStatus"/>  
        </template>
        <template v-else>
          <LivePlayer h5id="live3" :token="currentLoginedRobot.h5sToken.live3" :isDisable="true" :isShowMiddleBtn="false" :key="videoKey"  :isStop="isStop" :videoStatus="videoStatus" :videoModeType="videoModeType" :isPlayRed="isPlayRed"/>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import LivePlayer from "./LivePlayer";
import { mapState } from "vuex";
import { openVideoServer } from "@/http/professionalControlApi.js";
export default {
  name: "centerVideo",

  props:[
    'modeType',     // 视觉模式还是手动模式。0:手动; 1:视觉 当机器类型是ROBOt_TYPE_25 是视觉模式
    'videoStatus',  // '' 恢复手动; 0 停止播放; 1 奇臂; 2 偶臂; 3 视觉模式下停止播放
    'videoKey',     // 标志视频,使得切换组件时要执行mounted
    'isStop',       // 1：关闭视频
  ],

  components: {
    LivePlayer
  },

  data() {
    return {
      videoModeType: "可见光",      // 在视觉模式下左下角视频选择可见光还是红外
      videoModeKey: 0,
      isPlayRed: 0
    };
  },

  computed: {
    ...mapState(["currentLoginedRobot"])
  },

  watch: {
    /*
     * @Date 2022/06/17 17:03:28
     * 切换手动和视觉模式: 改变视频的播放状态
     */
    modeType() {
      if(this.modeType == 0) {
        this.videoStatus = '';
        this.videoModeType = '可见光';
      }else {
        this.isStop +=1;
        console.log(this.isStop);
      }
    },
    
    videoModeType() {
      this.videoModeKey += 1;
    }
  },
  methods:{
    isOpenRed(data) {
      console.log(data)
      this.isPlayRed = data;
    }
  }
};
</script>
<style lang="scss" scoped>
.center-video-component {
  width: 100%;
  height: 100%;
  .top-arm-video {
    height: 50%;
    width: 100%;
    border: 1px solid #0aaf8860;
  }
  .visible-infrared-video {
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .visible-video {
      width: 50%;
      height: 100%;
      border: 1px solid #0aaf8860;
      position: relative;
      .video-type-tab {
        position: absolute;
        top: 12px;
        left: 12px;
        z-index: 999;
      }
    }
    .infrared-video {
      width: 50%;
      height: 100%;
      border: 1px solid #0aaf8860;
    }
  }
}
</style>
