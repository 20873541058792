// 云台控制组件接口
import axios from "./ajax.js"; // 导入ajax

/**
 * @Author KR0288
 * @Date 2022/05/18 10:05:28
 * @Description 云台复位
 */
export const ptzInit = params => {
  return axios({
    url: "/eprdms/control/ptz/init",
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:05:56
 * @Description 云台运动
 */
export const ptzWalk = params => {
  return axios({
    url: "/eprdms/control/ptz/walk",
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:06:09
 * @Description 云台停止
 */
export const ptzStop = params => {
  return axios({
    url: "/eprdms/control/ptz/stop",
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:06:17
 * @Description 获取云台位置
 */
export const ptzGetpos = params => {
  return axios({
    url: "/eprdms/control/ptz/getpos",
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:07:34
 * @Description 设置云台位置
 */
export const ptzSetpos = params => {
  return axios({
    url: "/eprdms/control/ptz/setpos",
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:10:02
 * @Description 可见光变倍 0-25000
 */
export const aszoom = params => {
  return axios({
    url: "/eprdms/control/common/aszoom",
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:12:12
 * @Description 可见光自动对焦
 */
export const asfocus = params => {
  return axios({
    url: "/eprdms/control/common/asfocus",
    method: "put",
    params
  });
};

/**
 * /common/autofocus红外自动对焦
 */
export const autofocus = params => {
  return axios({
    url: "/eprdms/control/common/autofocus",
    method: "put",
    params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:12:53
 * @Description 请求红外-可见光拍照
 */
export const snap = params => {
  return axios({
    url: "/eprdms/control/common/snap",
    method: "put",
    params
  });
};
