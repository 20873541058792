<template>
  <div class="video-container" @click.stop="isShowMenu = false">
    <div class="video-item" :ref="item.id + '_container'" v-for="(item, index) in videoGroup" :key="item.id" @contextmenu.prevent="openMenu($event, index)">
      <div class="title">{{ item.title ? item.title : "视频"+ (index + 1)}}</div>
      <video class="h5video" preload="auto" style="object-fit:fill;" :id="item.id" :ref="item.id"></video>
      <div class="btn-div" v-if="item.h5handler">
        <span @click="FullScreen(index)" style="margin-right:1vh;"><i class="el-icon-full-screen"></i></span>
        <span @click="closeVideo(index)"><i class="el-icon-circle-close"></i></span>
      </div>
      <button
        :disabled="getIsDisabled"
        :title="getIsDisabled ? '请先登录机器人' : '点击播放'"
        :class="[getIsDisabled ?'btn-disable':'' , 'playBtn']"
        v-if="!item.h5handler && $hasElement('camera-btn-play')"
        ref="playBtn"
        @click="openVideo(index)"
      ></button>
    </div>
    <div class="menu" v-show="isShowMenu" ref="menuRef">
      <div class="menu-item" v-for="cameraItem in cameraList" :key="cameraItem.id" @click="handleChooseCamera(cameraItem)">
        {{ cameraItem.cameraName }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  openDistributedVideoServerApi
} from "@/http/professionalControlApi";
import { H5sPlayerWS } from "@/utils/h5s/h5splayer";
import { mapState, mapGetters } from "vuex";
export default {
  props: {
    /**
     * @description: 多路摄像头视频配置
     * @date 2023-07-20 17:18
     */
    cameraList: {
      type: Array,
      default: () => ([])
    }
  },

  data() {
    return {
      /**
       * @description: 当前4框的视频配置
       * @date 2023-07-20 17:20
       */
      currentVideoIndex: 0,
      videoGroup: [
        {
          title: "",
          token: "",
          cameraId: "",
          h5handler: null,
          id: "video_1"
        },
        {
          title: "",
          token: "",
          cameraId: "",
          h5handler: null,
          id: "video_2"
        },
        {
          title: "",
          token: "",
          cameraId: "",
          h5handler: null,
          id: "video_3"
        },
        {
          title: "",
          token: "",
          cameraId: "",
          h5handler: null,
          id: "video_4"
        }
      ],

      isShowMenu: false
    }
  },

  computed: {
    ...mapState(["session", "currentLoginedRobot"]),
    ...mapGetters(["getIsDisabled"]),
  },

  methods: {
    /**
     * @description: 打开摄像头菜单
     * @date 2023-07-21 09:41
     */
    openMenu(e, index) {
      this.isShowMenu = true;
      this.currentVideoIndex = index
      this.$refs.menuRef.style.left = e.clientX + "px";
      this.$refs.menuRef.style.top = e.clientY + "px";
    },

    /**
     * @description: 开启视频
     * @date 2023-07-21 09:42
     */
    // 通过选择摄像头路数
    handleChooseCamera(item) {
      this.videoGroup[this.currentVideoIndex].cameraId = item.id;
      this.openCloseVideoStream(item.id, 1).then(res => {
        if (res) {
          if (this.videoGroup[this.currentVideoIndex].h5handler) {
            this.closeVideo(this.currentVideoIndex);
          }

          let conf = null;
          conf = {
            videoid: this.videoGroup[this.currentVideoIndex].id,
            protocol: window.IPConfig.h5sApiURL.split("//")[0],
            // host: item.rtmp.split("/")[2] + "/" + item.rtmp.split("/")[3],
            host: window.IPConfig.h5sApiURL.split("//")[1],
            rootpath: "/",
            token: item.h5Token,
            hlsver: "v1",
            session: this.session
          }
          this.videoGroup[this.currentVideoIndex].h5handler = new H5sPlayerWS(conf);
          this.videoGroup[this.currentVideoIndex].h5handler.connect();
          this.videoGroup[this.currentVideoIndex].title = item.cameraName;
          this.videoGroup[this.currentVideoIndex].token = item.h5Token;
          this.videoGroup[this.currentVideoIndex].cameraId = item.id
        }
      })
    },

    // 通过点击播放按钮默认播放
    openVideo(index) {
      this.currentVideoIndex = index;
      this.handleChooseCamera(this.cameraList[index])
    },

    /**
     * @description: 开启 / 关闭视频推流
     * @date 2023-07-21 10:42
     */
    openCloseVideoStream(id, status) {
      return new Promise((resolve, reject) => {
        openDistributedVideoServerApi({
          cameraId: id,
          status
        }).then(res => {
          resolve(true)
        })
      }).catch(err => {
        reject(false)
      })
    },

    /**
     * @Author KR0288
     * @Date 2022/06/20 08:49:44
     * @Description 关闭视频
     */
    closeVideo(index) {
      if (this.videoGroup[index].cameraId) this.openCloseVideoStream(this.videoGroup[index].cameraId, 0);
      if (this.videoGroup[index].h5handler) {
        this.videoGroup[index].h5handler.disconnect();
        this.videoGroup[index].h5handler = null;
        this.videoGroup[index].title = this.cameraList[index].cameraName;
        this.$refs[this.videoGroup[index].id][0].load();
        this.$refs[this.videoGroup[index].id][0].poster = "";
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/17 16:33:04
     * @Description 视频全屏方法
     */
     FullScreen(index) {
      var elem = this.$refs[this.videoGroup[index].id + "_container"][0];
      if (
        document.fullscreenEnabled || 
        document.webkitFullscreenEnabled || 
        document.mozFullScreenEnabled || 
        document.msFullscreenEnabled
      ) {
        if (
          document.fullscreenElement || 
          document.webkitFullscreenElement || 
          document.mozFullScreenElement || 
          document.msFullscreenElement
        ) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }

        } else {
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
          } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
          } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
          }
        }
      } else {
        console.log("Fullscreen is not supported on your browser.");
      }
    },
  },

  watch: {
    cameraList: {
      handler(newVal, oldVal) {
        if (newVal.length > 0) {
          this.videoGroup.forEach((item, index) => {
            item.title = newVal[index].cameraName;
            item.cameraId = newVal[index].id;
          });
        }
      },
      immediate: true
    },

    currentLoginedRobot: {
      handler(newVal, oldVal) {
        this.videoGroup.forEach((item, index) => {
          if (item.h5handler) {
            this.closeVideo(index);
          }
        })
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variable";
button {
  border: none;
}
.video-container {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  height: 100%;
  border: 1px solid #0aaf8860;
  .video-item {
    min-height: 0;
    position: relative;
    border: 1px solid #0aaf8860;

    .h5video {
      width: 100%;
      height: 100%;
    }

    .title {
      position: absolute;
      top: 5%;
      left: 5%;
      font-size: 1.6667vh;
    }

    .btn-div {
      position: absolute;
      right: 0;
      top: 0;
      span {
        cursor: pointer;
        i {
          width: 0.8333vw;
          height: 0.8333vw;
          &::before {
            font-size: 0.8333vw;
          }
        }
      }
    }

    .playBtn {
      width: 3.4vw;
      height: 3.4vw;
      background: url("~@/assets/imgs/green/robot-stop-active.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1000;
      &.btn-disable {
        cursor: not-allowed;
      }
    }
  }

  .menu {
    padding: 0.5208vw;
    position: absolute;
    left: 0;
    top: 0;
    background-color: $green-tableThBackgroundColor;

    .menu-item {
      height: 2.3148vh;
      font-size: 1.4815vh;
      line-height: 2.3148vh;
      margin:  0.463vh 0;

      &:hover {
        background-color: #028f6d;
      }
    }
  }
}
</style>