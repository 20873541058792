<template>
  <div class="cloud-panel" style="position:relative;">
    <!-- 多选框选择红外还是可见光 -->
    <el-checkbox-group v-model="cloudType" size="mini" style="position:absolute;top:-2vh;right:0;">
      <el-checkbox label="可见光">可见光</el-checkbox>
      <el-checkbox label="红外">红外</el-checkbox>
    </el-checkbox-group>

    <!-- 云台控制盘 -->
    <div class="cloud-control">
      <div class="cloud-direction">
        <div class="cloud-panel">
          <div class="up" @mousedown="cloudTurnTo('ptzup')" @mouseup="cloudStop" title="云台向上" v-if="$hasElement('ptz-btn-walk')"></div>
          <div class="down" @mousedown="cloudTurnTo('ptzdown')" @mouseup="cloudStop" title="云台向下" v-if="$hasElement('ptz-btn-walk')"></div>
          <div class="right" @mousedown="cloudTurnTo('ptzright')" @mouseup="cloudStop" title="云台向右" v-if="$hasElement('ptz-btn-walk')"></div>
          <div class="left" @mousedown="cloudTurnTo('ptzleft')" @mouseup="cloudStop" title="云台向左" v-if="$hasElement('ptz-btn-walk')"></div>
          <div class="reset" @click="cloudResetBtn" title="云台复位" v-if="$hasElement('ptz-btn-init')"></div>
        </div>
      </div>
      <div class="cloud-speed">
        <el-slider v-model="cloudSpeed" :min="speed.minSpeed" :max="speed.maxSpeed" vertical height="14vh" :disabled="isUseByCloudType"></el-slider>
      </div>
    </div>

    <!-- 摄像头放大缩小 -->
    <div class="cloud-camera-btn">
      <el-button :disabled="getIsDisabled || isUseByCloudType" class="normal-small-button" @click="aszoomBtn('aszoom-')" title="减小倍数" v-if="$hasElement('common-btn-aszoom')">
        <i class="el-icon-minus"></i>
      </el-button>
      <div>变 倍</div>
      <el-button :disabled="getIsDisabled || isUseByCloudType" class="normal-small-button" @click="aszoomBtn('aszoom+')" title="增大倍数" v-if="$hasElement('common-btn-aszoom')">
        <i class="el-icon-plus"></i>
      </el-button>
    </div>

    <!-- 拍照和对焦 -->
    <div class="cloud-camera-btn">
      <el-button :disabled="getIsDisabled || isUseByCloudType" class="normal-small-button" @click="snapPicture" title="拍照" v-if="$hasElement('common-btn-snap')">
        拍照
      </el-button>
      <div></div>
      <el-button :disabled="getIsDisabled || isUseByCloudType" class="normal-small-button" @click="focusBtn" title="对焦" v-if="$hasElement('common-btn-autofocus')">
        对焦
      </el-button>
    </div>
    
    <!-- 抓拍 -->
    <el-dialog 
      class="snapPicture kr-dialog" 
      title="拍照" 
      :visible.sync="snapPictureVisible" 
      top="10vh"
      :z-index="1000"
      :modal-append-to-body="false"
    >
      <img :src="snapName" alt="" style="width:100%;height:70vh;" />
    </el-dialog>      

  </div>
</template>
<script>
import { ptzInit, ptzWalk, ptzStop, aszoom, asfocus, autofocus, snap } from "@/http/cloudControlApi.js";
import { mapGetters, mapMutations, mapState } from "vuex";
import { md5Encryption2 } from "@/utils/js/md5.js"
export default {
  name: "cloudControl",


  data() {
    return {
      /*
       * @Date 2022/07/11 09:14:44
       * 云台摄像头类型
       */
      cloudType: ["可见光", "红外"],
      isUseByCloudType: false,        // 根据cloudType的个数是否禁用云台控制


      /*
       * @Date 2022/07/11 09:15:39
       * 拍照
       */
      snapName: "",
      snapPictureVisible: false,


      /*
       * @Date 2022/07/11 09:16:08
       * 云台控制信息
       */
      cloudSpeed: 30,   // 云台转动速度
      ptzMsg: {         // 云台控制指令信息
        uart: 0
      },
    };
  },


  computed: {
    ...mapState(["snapPictureData", "currentLoginedRobot"]),
    ...mapGetters(["getIsDisabled"]),
    speed() {
      let minSpeed = 0;
      let maxSpeed = 100;
      switch (this.currentLoginedRobot?.robotType) {
        case "ROBOT_TYPE_25":
        case "ROBOT_TYPE_50":
          minSpeed = 0;
          maxSpeed = 400;
          break;

        case "ROBOT_TYPE_DISTRIBUTED":
          minSpeed = 1;
          maxSpeed = 300;
          break;

        default:
        minSpeed = 0;
          maxSpeed = 400;
      }
      return {
        minSpeed,
        maxSpeed
      }
    }
  },


  watch: {
    snapPictureData: {
      async handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          if (newValue.result == "1") {
            this.$message({
              message: newValue.data.desc,
              type: "success"
            });
            this.snapName = await md5Encryption2(newValue.data.name);
            if(this.$route.name == this.$parent.$el.className.split("-")[0]) {
              this.snapPictureVisible = true;
            }
          } else {
            this.$message({
              message: newValue.data.desc,
              type: "warning"
            });
          }
        }
      },
      deep: true
    },

    /*
     * @Date 2022/06/17 16:18:14
     * 可选光，红外变化触发修改命令参数和云台控制权限
     */
    cloudType: {
      handler(val) {
        // 1、判断是否有选择可选光 / 红外 / 两者，否则禁止使用云台控制
        this.isUseByCloudType = val.length != 0 ? false : true;

        // 2、修改命令参数
        if (val.length == 2 || val.length == 0) {
          this.ptzMsg.uart = 0;
        } else if (val[0] == "可见光") {
          this.ptzMsg.uart = 3;
        } else if (val[0] == "红外") {
          this.ptzMsg.uart = 5;
        }
      }
    },

    /*
     * @Date 2022/06/17 16:18:55
     * 云台控制权限的修改触发修改云台坐标的使用权限
     */
    isUseByCloudType: {
      handler(value) {
        this.$emit("returnIsUseCloudControl", value)
      }
    }
  },


  methods: {
    /**
     * @Author KR0288
     * @Date 2022/07/11 09:13:49
     * @Description 云台转动按钮
     */
    cloudTurnTo(cmd) {
      if (this.getIsDisabled || this.isUseByCloudType) {
        this.$message.error("请先选择可见光或红外")
        return;
      }
      ptzWalk({
        cmd: cmd,
        uart: this.ptzMsg.uart,
        velocity: this.cloudSpeed
      }).then(res => {
        if (res.code=="000000") {
          this.$message.success(res.mesg);
        } else {
          this.$message({
            message: res.mesg,
            type: "warning"
          });
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/11 09:20:37
     * @Description 云台复位按钮
     */
    cloudResetBtn() {
      if (this.getIsDisabled || this.isUseByCloudType) {
        this.$message.error("请先选择可见光或红外")
        return;
      }
      ptzInit({ uart: this.ptzMsg.uart }).then(res => {
        if (res.code=="000000") {
          this.$message.success(res.mesg);
        } else {
          this.$message({
            message: res.mesg,
            type: "warning"
          });
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/11 09:28:37
     * @Description 可见光变倍按钮
     */
    aszoomBtn(cmd) {
      aszoom({
        cmd: cmd
      }).then(res => {
        if (res.code=="000000") {
          this.$message.success(res.mesg);
        } else {
          this.$message({
            message: res.mesg,
            type: "warning"
          });
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/11 09:14:06
     * @Description 拍照
     */
    async snapPicture() {

      // 如果只选红外就拍红外
      if (this.ptzMsg.uart == 5) {
        let res = await snap({ cmd: "irsnap" });
        if (res.code=="000000") {
          this.$message({
            message: "拍照指令发送成功",
            type: "success"
          });
        } else {
          this.$message({
            message: res.mesg,
            type: "error"
          });
        }

      // 其他情况只拍可见光
      } else {
        let res = await snap({ cmd: "assnap" });
        if (res.code=="000000") {
          this.$message({
            message: "拍照指令发送成功",
            type: "success"
          });
        } else {
          this.$message({
            message: res.mesg,
            type: "error"
          });
        }
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/07/11 09:30:56
     * @Description 对焦按钮
     */
    async focusBtn() {
      if (this.cloudType.length === 2) {
        let res = await asfocus();
        let res1 = await autofocus();
        if (res.code=="000000" || res1.code=="000000") {
          this.$message.success("对焦指令发送成功");
        }
      } else if (this.cloudType[0] === "可见光") {
        let res = await asfocus();
        if (res.code=="000000") {
          this.$message.success("对焦指令发送成功");
        }
      } else {
        let res1 = await autofocus();
        if (res1.code=="000000") {
          this.$message.success("对焦指令发送成功");
        }
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/07/11 09:37:12
     * @Description 停止云台
     */
    cloudStop() {
      if (this.getIsDisabled || this.isUseByCloudType) {
        return;
      }
      ptzStop({
        uart: this.ptzMsg.uart,
        velocity: this.cloudSpeed
      }).then(res => {
        if (res.code!="000000") {
          this.$message({
            message: res.mesg,
            type: "warning"
          });
        }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.cloud-panel {
  width: 100%;
  height: 100%;
  .cloud-camera-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
