<template>
  <div class="logPanel-component">

    <!-- 传感器信息 -->
    <div class="sensor-message">
      <div class="item robot">
        <div class="log-label">机器人: {{ currentLoginedRobot.robotControlToken ? currentLoginedRobot.robotName : "" }}</div>
        <div class="log-msg" :style="{ color: currentLoginedRobot.robotControlToken ? '#00ffbf' : 'yellow' }">
          【
          <span style="font-size:1.3vh;">{{ currentLoginedRobot.robotControlToken ? "已登录" : "未登录" }}</span>
          】
        </div>
      </div>

      <div class="item">
        <div class="sub-item">
          <div class="log-label" title="点击切换显示方式" @click="isShowPercent = !isShowPercent">{{ 20 > Number(sensorstate.velocityPercent && sensorstate.velocityPercent.replace("%", "")) ? "低电量：" : "电量：" }}</div>
          <div class="log-msg" title="点击切换显示方式" @click="isShowPercent = !isShowPercent" v-show="isShowPercent">{{ sensorstate.velocityPercent }}</div>
          <div class="log-msg" title="点击切换显示方式" @click="isShowPercent = !isShowPercent" v-show="!isShowPercent">{{ sensorstate.velocity }} V</div>
        </div>
        <div class="sub-item">
          <div class="log-label">电流：</div>
          <div class="log-msg">{{ sensortemperature[1] | remove0 }}A</div>
        </div>
      </div>

      <div class="item">
        <div class="sub-item">
          <div class="log-label">线路：</div>
          <div class="log-msg">{{ lineName }}</div>
        </div>
        <div class="sub-item">
          <div class="log-label">坡度：</div>
          <div class="log-msg">{{ sensorstate.gradient }}°</div>
        </div>
      </div>

      <div class="item">
        <div class="sub-item">
          <div class="log-label">经度：</div>
          <div class="log-msg">{{ robotAddress.longitude }}°</div>
        </div>
        <div class="sub-item">
          <div class="log-label">纬度：</div>
          <div class="log-msg">{{ robotAddress.latitude }}°</div>
        </div>
      </div>

      <div class="item">
        <div class="sub-item">
          <div class="log-label">奇轮温度：</div>
          <div class="log-msg">{{ sensortemperature[2] | remove0 }} ℃</div>
        </div>
        <div class="sub-item">
          <div class="log-label">偶轮温度：</div>
          <div class="log-msg">{{ sensortemperature[3] | remove0 }} ℃</div>
        </div>
      </div>

      <div class="item">
        <div class="sub-item">
          <div class="log-label">箱内温度：</div>
          <div class="log-msg">{{ sensortemperature[4] | remove0 }} ℃</div>
        </div>
        <div class="sub-item">
          <div class="log-label">箱底温度：</div>
          <div class="log-msg">{{ sensortemperature[5] | remove0 }} ℃</div>
        </div>
      </div>

      <div class="item">
        <div class="sub-item">
          <div class="log-label">当前塔：</div>
          <div class="log-msg">{{ currentTower }}</div>
        </div>
        <div class="sub-item">
          <div class="log-label">距离此塔：</div>
          <div class="log-msg">{{ currentTowerDistance }} m</div>
        </div>
      </div>

      <div class="item">
        <div class="sub-item">
          <div class="log-label">下一塔：</div>
          <div class="log-msg">{{ nextTower }}</div>
        </div>
        <div class="sub-item">
          <div class="log-label">距离此塔：</div>
          <div class="log-msg">{{ nextTowerDistance }} m</div>
        </div>
      </div>
    </div>

    <!-- 日志信息 -->
    <div class="log-list">
      <ul ref="logList">
        <li v-for="(log, index) in logList" ref="logList" :key="index">
          <span class="log-time">[{{ log.logTime }}]</span>
          <span class="log-msg">{{ log.log }}</span>
        </li>
      </ul>
      <div class="clear-refresh">
        <div style="display:flex;justify-content:flex-end;">
          <el-button type="text" @click="refreshLog" title="刷新日志">刷新</el-button>
          <el-button type="text" style="margin-right:1vh" @click="clearLog" title="清除日志">清除</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { robotQuery } from "@/http/professionalControlApi.js";
import moment from "moment";
export default {
  name: "logPanel",
  data() {
    return {
      /*
       * @Date 2022/06/15 16:07:42
       * 传感器信息
       */
      isShowPercent: true,      // 电量显示百分比还是V
      lineName: "",             // 机器人所在线路
      currentTower: "",         // 当前塔
      currentTowerDistance: "", // 距离当前塔塔距离
      nextTower: "",            // 下一个塔
      nextTowerDistance: "",    // 距离下一个塔的距离


      /*
       * @Date 2022/06/15 16:11:02
       * 日志信息
       */
      logList: [],              // 日志列表
      temperature: [],          // 温度列表
    };
  },

  filters: {
    /*
     * @Date 2022/06/15 16:12:06
     * 去除首位的0
     */
    remove0: function(value) {
      if (!value) return "";
      value = value.toString();
      value = value.replace(/\b(0+)/i, "");
      if(value < 1){
        value = value.replace(".","0.")
      }
      return value;
    },
  },

  computed: {
    ...mapState([
      "currentLoginedRobot", 
      "socketContent", 
      "keepalive", 
      "sensorstate",
      "errorContent",
      "robotAddress",
      "snapPictureData",
      "sleepWaveUpMsg"
    ]),

    /*
     * @Date 2022/06/15 16:16:04
     * 传感器温度
     */
    sensortemperature() {
      return this.sensorstate.sensortemperature.split(",");
    }
  },

  methods: {
    /**
     * @Author KR0288
     * @Date 2022/06/15 16:16:42
     * @Description 刷新操作日志
     */
    refreshLog() {
      robotQuery({
        cmd: "sensor"
      }).then(res => {
        if (res.code=="000000") {
          this.$message.success("发送成功！");
        } else {
          this.$message.warning(res.mesg);
        }
      });
    },

    /**
     * @Author KR0288
     * @Date 2022/06/15 16:18:10
     * @Description 清除日志
     */
    clearLog() {
      this.logList = [];
    }
  },

  updated() {
    // 刷新的时候跳转到最后一个日志
    if (this.$refs.logList !== undefined) {
      this.$refs.logList.scrollTop = this.$refs.logList.scrollHeight;
    }
  },

  watch: {
    /*
     * @Date 2022/06/15 16:20:19
     * 登录了其他机器人就更新操作日志
     */
    "currentLoginedRobot.robotControlToken"(newValue, oldValue) {
      if (newValue) {
        this.refreshLog();
      }
    },

    /*
     * @Date 2022/06/15 16:20:49
     * 传感器信息发生改变就要产生操作日志
     */
    socketContent: {
      handler(newValue) {
        if (newValue.motorstate != "") {
          let logTime = moment().format("HH:mm:ss");

          // 1、其他信息导致的传感器信息改变，产生一条刷新成功日志
          if (newValue.motorstate == -1) {
            this.logList.push({
              logTime: logTime,
              log: "刷新成功"
            });

          // 2、由于机器人操作导致传感器信息改变，产生日志
          } else {
            var log = "";
            if (newValue.data.count != null && newValue.data.count != "") {
              log = newValue.motorstate + " " + newValue.data.count / 1000 + "m";
            } else {
              log = newValue.motorstate;
            }
            if (!(log === "" || log === undefined || log === null)) {
              this.logList.push({
                logTime: logTime,
                log: log
              });
            }
          }
        }
      },
      deep: true
    },

    /*
     * @Date 2022/06/15 16:28:55
     * 根据心跳更新机器人在线路的实际位置
     */
    keepalive: {
      handler(newValue) {
        if (newValue) {
          this.currentTower = newValue.current_towernumber != null ? newValue.current_towernumber : "";
          this.currentTowerDistance = newValue.current_distance != null ? newValue.current_distance : "";
          this.nextTower = newValue.next_towernumber != null ? newValue.next_towernumber : "";
          this.nextTowerDistance = newValue.next_distance != null ? newValue.next_distance : "";
          this.lineName = newValue.lineName != null ? newValue.lineName : "";
        }
      },
      deep: true
    },

    /*
     * @Date 2022/06/15 16:30:01
     * 错误信息保存在日志中
     */
    errorContent: {
      handler(newValue) {
        if (newValue.data.desc != null) {
          let logTime = moment().format("HH:mm:ss");
          this.logList.push({
            logTime: logTime,
            log: newValue.data.desc
          });
        }
      },
      deep: true
    },

    /*
     * @Date 2022/06/17 13:14:38
     * 拍照照片存放的位置保存在日志中
     */
    snapPictureData: {
      handler(newValue) {
        if(newValue?.data?.name != "") {
          this.logList.push({
            logTime: moment().format("HH:mm:ss"),
            log: "图片路径: " + newValue.data.name
          })
        }
      },
      deep: true
    },

    /**
     * @description: 监听分布式机器人休眠唤醒
     * @date 2023-08-16 17:11
     */
    sleepWaveUpMsg: {
      handler(newVal, oldVal) {
        this.logList.push(newVal)
      },
      deep: true
    }
  }
};
</script>
<style lang="scss" scoped>
.logPanel-component {
  .sensor-message {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 0.5vh;
    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 1vh;
      margin: 0.3vh;
      .sub-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 50%;
        .log-label {
          width: 50%;
          min-width: 60px;
          text-align-last: justify;
          // text-align: right;
        }
        .log-msg {
          width: 50%;
          padding: 0 0.2vh;
        }
      }
    }
    .robot {
      width: 100%;
    }
  }
  .log-list {
    ul {
      padding: 0;
      margin: 0;
      height: 22vh;
      overflow-y: auto;
      li {
        list-style: none;
        font-size: 1.3vh;
        margin-bottom: 0.5vh;
        .log-time {
          display: inline-block;
          color: yellow;
          width: 3.5vw;
        }
        .log-msg {
          color: #fff;
        }
      }
    }
  }
}
</style>
